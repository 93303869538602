import { useMemo, useState } from 'react'
import { AIRCRAFT_COLLECTION, ROLE_CAPTAIN, TEST_COLLECTION } from '../_constants/globals'
import useGoogleApi from './useGoogleApi'
import { useFirestore } from './useFirestore'
import { useSelector } from 'react-redux'
import { EASA_FLIGHT_PURPOSES } from '../_constants/misc'
import { chain } from 'lodash'
import { formatDuration, getTypeLabel } from '../_helpers/dataHelper'
import { format, intervalToDuration, parse } from 'date-fns'


const FLIGHT_CONDITIONS_DOC = 'FCD-I-00002-A- FLIGHT CONDITIONS JUSTIFICATION DOCUMENT'
const PERMIT_TO_FLY_REF = 'N° 0561/23/NO/NAV'
const PERMIT_TO_FLY_VALIDITY = '11/11/2024'
const TEST_ORDER_TEMPLATE_ID = '1s-64MzKCimIu0EiB-oI5gQxRo7SLMxH1KBfeS2jwt3I'
const CER_TEMPLATE_ID = '1fj1exnRd4xhBYqsjevuFs4C8X7Dc1FjP'
const TEST_REPORT_TEMPLATE_ID = '1Ymu1omD1AALXv_pqqKkdu104y0mBpnmdB7yw-7QVB5A'

const useTaskController = test => {

  const { ready, createFolder, copyTemplate, hydrateTestOrder, getDocumentUrl } = useGoogleApi()
  const testHooks = useFirestore(TEST_COLLECTION)
  const selectedTestId = useSelector(state => state.data.selectedTestId)
  const profile = useSelector(state => state.profile.data)
  const aircraftHooks = useFirestore(AIRCRAFT_COLLECTION)
  const dbAircrafts = aircraftHooks.getDocs()

  const [busy, setBusy] = useState(false)

  const aircraft = useMemo(() => dbAircrafts.find(a => a.id === test.aircraftRef.id), [dbAircrafts, test])

  const folderName = format(test.startDate, 'yyyy.MM.dd') + '-' + test.label?.split('-').slice(-1) + ' ' + test.title

  const createDriveFolder = () => {
    setBusy(true)
    return createFolder(folderName, aircraft.driveFolderId)
      .then(folder =>  testHooks.updateDoc(selectedTestId, { driveFolderId: folder.id }))
      .finally(() => setBusy(false))
  }

  const createTestOrder = () => {
    setBusy(true)
    const testOrderName = `TO-${aircraft.type}-${aircraft.serial.slice(-2)}-${test.label.split('-').slice(-1)} : ${test.title}`
    const requests = [
      {
        replaceAllText: {
          containsText: { text: 'TO-P-XXXXX' },
          replaceText: `TO-${aircraft.type}-${aircraft.serial.slice(-2)}-${test.label.split('-').slice(-1)}`,
        },
      },
      {
        updateTextStyle: {
          textStyle: {
            foregroundColor: { color: { rgbColor: { red: 0, green: 0, blue: 0 } } },
            weightedFontFamily: { fontFamily: 'Roboto' },
          },
          fields: '*',
          range: { segmentId: 'kix.hf0', startIndex: 0, endIndex: 80 },
        },
      },
      {
        replaceAllText: {
          containsText: { text: 'Enter the date of test ' },
          replaceText: format(test.startDate, 'yyyy.MM.dd'),
        },
      },
      {
        replaceAllText: {
          containsText: { text: 'Type :Taxi / Engine Run / Flight ' },
          replaceText: test.testType.charAt(0).toUpperCase() + test.testType.slice(1),
        },
      },
      {
        replaceAllText: {
          containsText: { text: 'Enter the Manufacturer Serial Number(MSN) of the aircraft here' },
          replaceText: aircraft.serial,
        },
      },
      {
        replaceAllText: {
          containsText: { text: 'Enter the Aircraft registration' },
          replaceText: aircraft.registration,
        },
      },
      {
        replaceAllText: {
          containsText: { text: 'Enter the Reference number for the flight test / ground test\nFxxx or Gxxx or Rxxx\n' },
          replaceText: test.label,
        },
      },
      {
        replaceAllText: {
          containsText: { text: 'Enter the reference for the approved flight conditions\n FCD-P-XXXXX' },
          replaceText: FLIGHT_CONDITIONS_DOC,
        },
      },
      {
        replaceAllText: {
          containsText: { text: 'Enter the validity of the Approved Flight Conditions' },
          replaceText: 'N/A',
        },
      },
      {
        replaceAllText: {
          containsText: { text: 'Enter the reference for the approved Permit to Fly' },
          replaceText: PERMIT_TO_FLY_REF,
        },
      },
      {
        replaceAllText: {
          containsText: { text: 'Enter the validity of the Approved Permit to Fly' },
          replaceText: PERMIT_TO_FLY_VALIDITY,
        },
      },
      {
        replaceAllText: {
          containsText: { text: 'Refer to E_D04 Flight Test Operational Manual for types of flight' },
          replaceText: Object.values(EASA_FLIGHT_PURPOSES.find(p => p.code === test.purpose)).join(' - '),
        },
      },
      {
        replaceAllText: {
          containsText: { text: 'Refer to E_D04 Flight Test Operational Manual for classification of flight' },
          replaceText: 'class ' + test.classification,
        },
      },
      {
        replaceAllText: {
          containsText: { text: 'List the flight crew members' },
          replaceText: test.users?.map(u => u.firstname + ' ' + u.lastname).join(' / '),
        },
      },
      {
        replaceAllText: {
          containsText: { text: 'March xxth 2022' },
          replaceText: format(new Date(), 'yyyy.MM.dd'),
        },
      },
      {
        replaceAllText: {
          containsText: { text: 'Author FULLNAME' },
          replaceText: profile.firstname + ' ' + profile.lastname?.toUpperCase(),
        },
      },
      {
        replaceAllText: {
          containsText: { text: 'PIC FULLNAME' },
          replaceText: chain(test.users).find(u => u.role === ROLE_CAPTAIN)?.pick('firstname', 'lastname').values().join(' ').value(),
        },
      },
      {
        replaceAllText: {
          containsText: { text: 'Enter the Take Off Mass and CG of the aircraft (as configured)' },
          replaceText: `Take off Mass: ${test.mass}, CG: ${test.gravity}%`,
        },
      },
    ]
    return copyTemplate(TEST_ORDER_TEMPLATE_ID, test.driveFolderId, testOrderName)
      .then(doc => hydrateTestOrder(doc, requests))
      .then(doc => testHooks.updateDoc(selectedTestId, { driveTestOrderId: doc.id }))
      .finally(() => setBusy(false))
  }

  const createCerRequest = () => {
    setBusy(true)
    const fileName = 'CER_Request'
    const requests = [
      {
        replaceAllText: {
          containsText: { text: '<DATE_OF_FLIGHT>' },
          replaceText: format(test.startDate, 'dd/MM/yyyy'),
        },
      },
      {
        replaceAllText: {
          containsText: { text: '<DEPARTURE_TIME_UTC>' },
          replaceText: test.startDate.toString().substring(11, 16),
        },
      },
      {
        replaceAllText: {
          containsText: { text: '<DEPARTURE_AIRFIELD>' },
          replaceText: test.departure.ICAO,
        },
      },
      {
        replaceAllText: {
          containsText: { text: '<ARRIVAL_AIRFIELD>' },
          replaceText: test.arrival.ICAO,
        },
      },
      {
        replaceAllText: {
          containsText: { text: '<AIRCRAFT_TYPE>' },
          replaceText: getTypeLabel(aircraft.type),
        },
      },
      {
        replaceAllText: {
          containsText: { text: '<AIRCRAFT_SERIAL>' },
          replaceText: aircraft.serial,
        },
      },
      {
        replaceAllText: {
          containsText: { text: '<AIRCRAFT_REGISTRATION>' },
          replaceText: aircraft.registration,
        },
      },
      {
        replaceAllText: {
          containsText: { text: '<POB>' },
          replaceText: test.users?.length.toString(),
        },
      },
      {
        replaceAllText: {
          containsText: { text: '<CAPTAIN>' },
          replaceText: test.users.find(u => u.role === ROLE_CAPTAIN)?.lastname,
        },
      },
      {
        replaceAllText: {
          containsText: { text: '<TITLE>' },
          replaceText: test.title,
        },
      },
      {
        replaceAllText: {
          containsText: { text: '<DESCRIPTION>' },
          replaceText: test.description,
        },
      },
      {
        replaceAllText: {
          containsText: { text: '<AUTHOR_EMAIL>' },
          replaceText: profile.email,
        },
      },
      {
        replaceAllText: {
          containsText: { text: '<AUTHOR_FULLNAME>' },
          replaceText: profile.firstname + ' ' + profile.lastname?.toUpperCase(),
        },
      },
    ]
    return copyTemplate(CER_TEMPLATE_ID, test.driveFolderId, fileName)
      .then(doc => hydrateTestOrder(doc, requests))
      .then(doc => testHooks.updateDoc(selectedTestId, { driveCerRequestId: doc.id }))
      .finally(() => setBusy(false))
  }

  const cerEmailAddress = 'dga-ev-istres.dcer.fct@intradef.gouv.fr'
  const cerEmailSubject = `[DEMANDE DE CRENEAU CER] : Demande de créneau CER ${format(test.startDate, 'dd/MM/yyyy')}`
  const cerEmailBody = `Bonjour,\n\n
  Veuillez trouver ci-joint les demandes de créneau CER pour le ${format(test.startDate, 'dd/MM/yyyy')} à ${format(test.startDate, 'HH:mm')}\n\n
  Cordialement,\n`
  const cerEmailLink = `mailto:${cerEmailAddress}?subject=${cerEmailSubject}&body=${cerEmailBody}`

  const createTestReport = () => {
    setBusy(true)
    const testReportName = `TR-${aircraft.type}-${aircraft.serial.slice(-2)}-${test.label.split('-').slice(-1)} : ${test.title}`
    const requests = [
      {
        replaceAllText: {
          containsText: { text: 'TR-P-XXXXX' },
          replaceText: `TR-${aircraft.type}-${aircraft.serial.slice(-2)}-${test.label.split('-').slice(-1)}`,
        },
      },
      {
        updateTextStyle: {
          textStyle: {
            foregroundColor: { color: { rgbColor: { red: 0, green: 0, blue: 0 } } },
            weightedFontFamily: { fontFamily: 'Roboto' },
          },
          fields: '*',
          range: { segmentId: 'kix.hf0', startIndex: 0, endIndex: 80 },
        },
      },
      {
        replaceAllText: {
          containsText: { text: 'DD/MM/YYYY' },
          replaceText: format(test.startDate, 'dd/MM/yyyy'),
        },
      },
      {
        replaceAllText: {
          containsText: { text: 'insert MSN number' },
          replaceText: test.serial,
        },
      },
      {
        replaceAllText: {
          containsText: { text: 'Type :Taxi / Engine Run / Flight ' },
          replaceText: test.testType.charAt(0).toUpperCase() + test.testType.slice(1),
        },
      },
      {
        replaceAllText: {
          containsText: { text: 'Enter the Manufacturer Serial Number(MSN) of the aircraft here' },
          replaceText: aircraft.serial,
        },
      },
      {
        replaceAllText: {
          containsText: { text: 'Enter the Aircraft registration' },
          replaceText: aircraft.registration,
        },
      },
      {
        replaceAllText: {
          containsText: { text: 'Enter the name of the test performed; ex: Climb Performance Flight Tests' },
          replaceText: test.title,
        },
      },
      {
        replaceAllText: {
          containsText: { text: 'Enter the reference for the test order' },
          replaceText: `TO-${aircraft.type}-${aircraft.serial.slice(-2)}-${test.label.split('-').slice(-1)}`,
        },
      },
      {
        replaceAllText: {
          containsText: { text: '09:55' },
          replaceText: format(test.offBlocksTime, 'HH:mm'),
        },
      },
      {
        replaceAllText: {
          containsText: { text: '10:00' },
          replaceText: format(test.takeOffTime, 'HH:mm'),
        },
      },
      {
        replaceAllText: {
          containsText: { text: '11:13' },
          replaceText: format(test.landingTime, 'HH:mm'),
        },
      },
      {
        replaceAllText: {
          containsText: { text: '11:16' },
          replaceText: format(test.onBlocksTime, 'HH:mm'),
        },
      },
      {
        replaceAllText: {
          containsText: { text: '1h 10 min' },
          replaceText: formatDuration(intervalToDuration({ start: parse(test.takeOffTime, 'HH:mm', test.startDate), end: parse(test.landingTime, 'HH:mm', test.startDate) })),
        },
      },
      {
        replaceAllText: {
          containsText: { text: '1h 20 min' },
          replaceText: formatDuration(intervalToDuration({ start: parse(test.offBlocksTime, 'HH:mm', test.startDate), end: parse(test.onBlocksTime, 'HH:mm', test.startDate) })),
        },
      },
      {
        replaceAllText: {
          containsText: { text: '<1>' },
          replaceText: test.landings.toString(),
        },
      },
      {
        replaceAllText: {
          containsText: { text: 'Summarise the document contents here. Brief information on the test objective, procedure, and the results can be given.\n' },
          replaceText: test.description,
        },
      },
      {
        replaceAllText: {
          containsText: { text: 'Enter the validity of the Approved Permit to Fly' },
          replaceText: PERMIT_TO_FLY_VALIDITY,
        },
      },
      {
        replaceAllText: {
          containsText: { text: 'March xxth 2022' },
          replaceText: format(new Date(), 'yyyy.MM.dd'),
        },
      },
      {
        replaceAllText: {
          containsText: { text: 'Author FULLNAME' },
          replaceText: profile.firstname + ' ' + profile.lastname?.toUpperCase(),
        },
      },
      {
        replaceAllText: {
          containsText: { text: 'PIC FULLNAME' },
          replaceText: chain(test.users).find(u => u.role === ROLE_CAPTAIN)?.pick('firstname', 'lastname').values().join(' ').value(),
        },
      },
    ]
    return copyTemplate(TEST_REPORT_TEMPLATE_ID, test.driveFolderId, testReportName)
      .then(doc => hydrateTestOrder(doc, requests))
      .then(doc => testHooks.updateDoc(selectedTestId, { driveTestReportId: doc.id }))
      .finally(() => setBusy(false))
  }

  return { ready, busy, createDriveFolder, createTestOrder, createCerRequest, cerEmailLink, getDocumentUrl, createTestReport }
}

export default useTaskController
