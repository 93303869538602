import React, { Fragment, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Card, CardContent, CardHeader, Divider, IconButton, Link, Menu, MenuItem, Stack } from '@mui/material'
import { TASK_COLLECTION, TEST_COLLECTION } from '../../_constants/globals'
import { useFirestore } from '../../hooks/useFirestore'
import Task from './Task'
import { useSelector } from 'react-redux'
import { Add, AddToDrive } from '@mui/icons-material'
import { TASKS_TEMPLATE } from '../../_constants/misc'
import { format } from 'date-fns'
import useTaskController from '../../hooks/useTaskController'


const TaskCard = ({ test, tasks }) => {

  const adminMode = useSelector(state => state.data.adminMode)
  const taskHooks = useFirestore(TASK_COLLECTION)
  const testHooks = useFirestore(TEST_COLLECTION)
  const taskController = useTaskController(test)
  const { ready, busy, createDriveFolder } = taskController
  const readOnlyMode = useSelector(state => state.data.readOnlyMode)

  const [anchorMenu, setAnchorMenu] = useState(null)
  
  const folderName = useMemo(() => format(test.startDate, 'yyyy.MM.dd') + '-' + test.label?.split('-').slice(-1) + ' ' + test.title, [test])

  const handleAddClick = template => {
    taskHooks.addDoc({
      ...template,
      testRef: testHooks.getDocRef(test.id),
    })
    setAnchorMenu(null)
  }

  return (
    <Card>
      <CardHeader
        title='La paille'
        subheader={test.driveFolderId
          ? <Link href={`https://drive.google.com/drive/u/1/folders/${test.driveFolderId}`} target='_blank' rel='noreferrer'>{folderName}</Link>
          : <Button type='text' disabled={!ready || busy || readOnlyMode} onClick={createDriveFolder}><AddToDrive /> Generate folder</Button>
        }
        action={adminMode && <IconButton onClick={e => setAnchorMenu(e.currentTarget)}><Add  /></IconButton>}
      />
      <CardContent>
        <Stack spacing={2}>
          {tasks?.map((task, index) =>
            <Fragment key={task.id}>
              {task.moment === 'after' && tasks[index-1].moment === 'before' && <Divider>FLIGHT</Divider>}
              <Task task={task} taskController={taskController} />
            </Fragment>,
          )}
        </Stack>
      </CardContent>
      <Menu
        anchorEl={anchorMenu}
        open={!!anchorMenu}
        onClose={() => setAnchorMenu(null)}
      >
        {TASKS_TEMPLATE.map(temp => <MenuItem key={temp.slug} onClick={() => handleAddClick(temp)}>{temp.slug}</MenuItem>)}
      </Menu>
    </Card>
  )
}

TaskCard.propTypes = {
  test: PropTypes.object.isRequired,
  tasks: PropTypes.array.isRequired,
}

export default TaskCard
