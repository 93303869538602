import React, { useEffect, useMemo } from 'react'
import AllTestsPanel from './AllTestsPanel'
import TestDetails from './TestDetails'
import { useDispatch } from 'react-redux'
import { useMatch } from 'react-router-dom'
import { TESTS } from '../../_constants/routes'
import { selectAircraftIdAction, selectTestIdAction } from '../../store/actions/dataActions'
import { TEST_COLLECTION } from '../../_constants/globals'
import { useFirestore } from '../../hooks/useFirestore'
import Page from '../_library/Page'
import { compact } from 'lodash'
import Loading from '../_library/Loading'


const TestPage = () => {
  
  const match = useMatch(TESTS + '/:id')
  const dispatch = useDispatch()
  const testHooks = useFirestore(TEST_COLLECTION)
  const tests = testHooks.getDocs()
  
  useEffect(() => {
    testHooks.listen({
      orderBy: [['startDate', 'asc']],
    })
    return () => testHooks.unsubscribe()
  }, [])

  const test = useMemo(() => match?.params?.id && tests?.find(t => t.id === match?.params.id), [tests, match?.params?.id])
  
  useEffect(() => {
    match?.params?.id && dispatch(selectTestIdAction(match?.params.id))
  }, [match?.params?.id])

  useEffect(() => {
    test && dispatch(selectAircraftIdAction(test.aircraftRef.id))
  }, [test?.aircraftRef])

  const tabs = compact([
    {
      key: 'all',
      label: 'All tests',
      route: 'all',
      content: <AllTestsPanel />,
    },
    match?.params?.id && {
      key: 'test',
      label: '',
      title: test?.label || '',
      route: '/:id',
      content: test ? <TestDetails test={test} /> : <Loading />,
    },
  ])

  return <Page tabs={tabs} withTopBarAndMenu />
}

export default TestPage
